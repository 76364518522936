import { L } from "@angular/cdk/keycodes";

// USA
export const locale = {
	lang: 'en',
	data: {
		TRANSLATOR: {
			SELECT: 'Select your language',
		},
		USER_PROFILE: {
			SETTINGS: 'Settings',
			HOTEL_OPTIONS: 'Hotel options and setup',
			HELP: 'Help & Support',
			HELP_GUID: 'Complete help guide and support',
			THEME: 'Theme',
			LANGUAGE: 'Language',
			SIGN_OUT: 'Sign Out',
			USERS: 'Users',
			MANAGE_USERS: 'Manage users and hotel permissions',
		},
		CONNECTS: {
			MENU: {
				OVERVIEW: 'Overview',
				GUESTS_SEGMENTS: 'Guests & Segments',
				GUESTS: 'Guests',
				SEGMENTS: 'Segments',
				LISTS: 'Lists',
				CAMPAIGNS: 'Campaigns',
				CAMPAIGN_LIST: 'Campaign List',
				TEMPLATES: 'Templates',
				REPORTS: 'Reports',
				CAMPAIGN_STATS: 'Campaign Statistics',
				GUESTSUMMARY: 'Guest Summary'
			},
			OVERVIEW: {
				TOTAL: 'Total Profiles',
				REACHABLE: 'Reachable Profiles',
				UNREACHABLE: 'Unreachable Profiles',
				EMAIL_COVERAGE: 'Email Coverage',
				CAMPAIGN: 'Campaign Room Revenue',
				YEAR: 'Year To Date',
				ONE_TIME: 'One Time Campaign Revenue',
				LAST: 'Last 5 Revenue',
				ALL: 'View All',
				LIFETIME: 'Lifetime Revenue',
				REVENUE: 'Total Revenue',
				ARRIVALS: 'Arrivals',
				NUMBER_OF_GUESTS: 'Number of Guests',
				ROOM_REVENUE: 'Room Revenue',
				NAME: 'Name',
				NIGHTS: 'Nights',
				LIFETIME_VALUE: 'Lifetime Value',
				EMAIL: 'Email',
				DEPARTURES: 'Departures',
			},
			GUESTS: {
				ALL_GUESTS: 'All Guests',
				ARRIVALS: 'Arrivals',
				HOUSE: 'In House',
				DEPARTURES: 'Departures',
				SEARCH_PLACEHOLDER: 'Search name, email, reference',
				DATE_PLACEHOLDER: 'Select date range',
				FILTERS: 'Filters',
				CHANGE_HOTEL: 'Change Hotel',
				SEARCH: 'Search',
				CLEAR: 'Clear',
				GUEST: 'Guest',
				NIGHTS: 'Nights',
				ROOM_TYPE: 'Room Type',
				REVENUE: 'Revenue',
				BOOKING: 'Booking Channel',
				RATE: 'Rate Code',
				EMAIL: 'Email',
				PREVIOUS: 'Previous Stays',
				LIFETIME: 'Lifetime Value',
				NOTES: 'Notes',
				NO_RESULTS: 'There were no results found',
				ITEMS: 'Items per page:',
			},
			SEGMENTS: {
				SEARCH_SEGMENTS: 'Search by segment name',
				CREATE_SEGMENT: 'Create Guest Segment',
				EDIT_SEGMENT: 'Edit Guest Segment',
				REFRESH: 'Refresh Now',
				STATISTICS: 'Statistics last updated',
				NO_RESULTS: 'There were no results found',
				REACHABLE: 'Reachable by email',
				PROFILES: 'Profiles',
				AVARAGE_NIGHTS: 'Average nights',
				AVARAGE_RATE: 'Average daily rate',
				OR: 'OR',
				EXCLUDE_BOOKINGS: 'Exclude bookings with future stays',
				VIEW_DETAILS: 'View Details',
				SELECT: 'Select',
				PEOPLE_IN_SEGMENT: 'people in this segment',
				TOTAL_LIFETIME: 'total lifetime spend',
				SEARCH_PLACEHOLDER: 'Search name, email, reference',
				SEARCH: 'Search',
				CLEAR: 'Clear',
				GUEST: 'Guest',
				EMAIL: 'Email',
				LIFETIME_VALUE: 'Lifetime Value',
				LIFETIME_STAYS: 'Lifetime Stays',
				TOTAL_NIGHTS: 'Total Nights',
				CLOSE: 'Close',
				ITEMS: 'Items per page:',
				SEGMENT_NAME: 'Segment Name',
				SEGMENT_REQUIRED: 'Segment name is required',
				CONTACT_LISTS: 'Contact Lists',
				ENTER_CONTACT: 'Enter contact list...',
				PROFILES_SEGMENT: 'Profiles in this segment',
				TOTAL_LIFETIME_UPPER: 'Total lifetime spend',
				REFRESH_STATICITCS: 'Refresh Statistics',
				FIND_GUESTS: 'Find guests who match the following conditions',
				AND: 'AND',
				SEARCH_VALUE: 'Enter search value',
				ENTER_DISTANCE: 'Enter distance',
				IMPLICIT_CONSENT: 'Implicit Consent (safe)',
				EXPLICIT_CONSENT: 'Explicit Consent (safer)',
				DOUBLE_CONSENT: 'Double Opt-In Consent (safest)',
				ENTER_DATE: 'Enter a date range',
				START_DATE: 'Start date',
				END_DATE: 'End date',
				START_DAYS: 'Start days',
				END_DAYS: 'End days',
				LAST_DAYS: 'Last days',
				EXCLUDE_GUESTS: 'Exclude guests with future stays',
				CORRECT_FILTERS: 'Please correct the errors in the segment filters',
				CANCEL: 'Cancel',
				SAVE: 'Save',
				BETWEEN_DATES: 'between dates',
				BETWEEN_FUTURE: 'between future days',
				DAYS: 'days',
				BETWEEN_PAST: 'between past days',
				LAST: 'in the last',
				EQUAL: 'equal to',
				NOT_EQUAL: 'not equal to',
				GREATER: 'greater than',
				LESS: 'less than',
				KM: 'km',
				CONTAINS: 'contains',
				NOT_CONTAINS: 'does not contain',
				IS_EQUAL: 'is equal to',
				IS_NOT_EQUAL: 'is not equal to',
				LEAST: 'has at least',
				ANNIVERSARY: 'Anniversary',
				BADGE: 'Badge',
				BIRTHDAY: 'Birthday',
				COMPANY: 'Company',
				EMAIL_ADDRESS: 'Email address',
				GENDER: 'Gender',
				LANGUAGE: 'Language',
				LIFETIME_ROOM: 'Lifetime Room Spend',
				LOCATION: 'Location',
				TOTAL_NUMBER_NIGHTS: 'Total # Of Nights',
				TOTAL_NUMBER_STAYS: 'Total # Of Stays',
				SUBSCRIPTION: 'Subscription Status',
				BOOKING: 'Booking Channel',
				BOOKING_DATE: 'Booking Date',
				BOOKING_LEAD: 'Booking Lead Time',
				CHECK_IN_DATE: 'Check-in Date',
				CHECK_OUT_DATE: 'Check-out Date',
				LENGTH: 'Length of Stay',
				MARKET: 'Market Code',
				RATE: 'Rate Code',
				ROOM: 'Room Code',
				RATE_AVARAGE: 'Rate Average',
				SOURCE: 'Source of Business',
				STAY: 'Stay Date',
				DEMOGRAPHICS: 'Demographics',
				MARKETING: 'E-mail Marketing',
				STAY_DATA: 'Stay Data',
				IMPORT_GUESTS: 'Import Guests',
				ADD_LIST: 'Add List',
				HOTEL_NAME: 'Hotel Name',
				GROUPWIDE: 'Groupwide',
				NAME: 'Name',
				GUESTS: 'Guests',
				EDIT_LIST: 'Edit list',
				UPDATE_LIST: 'Update List',
				LIST_NAME: 'List Name',
				ROOM_REQUIRED: 'Room type name is required',
				SELECT_HOTEL: 'Select Hotel',
				SELECT_DATA: 'Select Data',
				SELECT_LIST: 'Select List',
				PLEASE_SELECT_LIST: 'Please select a list',
				SUBSCRIPTION_STATUS: 'Subscription Status',
				UNSUBSCRIBED: 'Unsubscribed',
				IMPLICIT: 'Implicit',
				EXPLICIT: 'Explicit',
				DOUBLE_OPT_IN: 'Double Opt In',
				PLEASE_SELECT_SUBSCRIPTION: 'Please select a subscription status',
				PLEASE_UPLOAD_FILE: 'Please upload a file',
				DOWNLOAD_EXAMPLE: 'Download an example .csv file',
				NEXT: 'Next',
				COMPLETE_MAPPINGS: 'Complete mappings',
				BACK: 'Back',
				IMPORT: 'Import',
				ERROR_UPLOADING: 'Sorry, there was an error uploading the guests',
				UPLOAD_FILE: 'Upload file',
				NO_FILE: 'No file chosen',
			}, 
			GUESTS_PROFILE: {
				LEAD_TIME: 'Lead Time',
				LIFETIME_STAYS: 'Lifetime Stays',
				LIFETIME_NIGHTS: 'Lifetime Nights',
				LIFETIME_SPEND: 'Lifetime Spend',
				STATUS: 'Status',
				HOUSE: 'In House',
				LAST_STAY: 'Last Stay',
				NEXT_STAY: 'Next Stay',
				NIGHTS: 'Nights',
				TODAY: 'Today',
				PERSONAL: 'Personal',
				TITLE: 'Title',
				FIRST_NAME: 'First Name',
				LAST_NAME: 'Last Name',
				GENDER: 'Gender',
				LANGUAGE: 'Language',
				COMPANY: 'Company',
				JOB_TITLE: 'Job Title',
				ADDRESS_1: 'Address 1',
				ADDRESS_2: 'Address 2',
				CITY_COUNTY: 'City, County',
				POST_CODE: 'Post Code',
				COUNTRY: 'Country',
				OTHER: 'Other',
				BIRTHDAY: 'Birthday',
				ANNIVERSARY: 'Anniversary',
				PROFILE_NOTES: 'Profile Notes',
				EMAIL: 'Email',
				SUBSCRIPTION_STATUS: 'Subscription Status',
				BADGES: 'Badges',
				CONTACT_LISTS: 'Contact Lists',
				BOOKING_NOTES: 'Booking Notes',
				LOYALTY: 'Loyalty',
				FIRST_STAY: 'First Stay W/ Group',
				LIFETIME_VALID_STAYS: 'Lifetime Valid Stays',
				LIFETIME_CANCELLED: 'Lifetime Cancelled Stays',
				LIFETIME_VALID_NIGHTS: 'Lifetime Valid Nights',
				CURRENT_STAY: 'Current Stay',
				NO_STAYS: 'No future stays',
				CHECK_IN: 'Check In',
				CHECK_OUT: 'Check Out',
				OF_NIGHTS: '# of nights',
				BOOKING_REF: 'Booking Ref',
				HOTEL: 'Hotel',
				ROOM_TYPE: 'Room Type',
				RATE: 'Rate',
				CHANNEL: 'Channel',
				GUESTS: 'Guests',
				ROOM: 'Room',
				BOOKING_DATE: 'Booking Date',
				NIGTH: 'night',
				ALL_STAYS: 'All Stays',
				CANCELLED: 'Cancelled',
				IN_HOUSE: 'In House',
				GUEST_MARKETING: 'Guest Marketing',
				DATE_SENT: 'Date Sent',
				EVENT_DATE: 'Event Date',
				CAMPAIGN: 'Campaign',
				PENDING_REQUESTS: 'Pending Requests',
				DATE: 'Date',
				READ_ON: 'Read on',
				REQUESTS: 'Requests',
				TYPE: 'Type',
				READ: 'Read',
				UNREAD: 'Unread',
				ADD_BADGE: 'Add Badge',
				BADGE: 'Badge',
				CANCEL: 'Cancel',
				SAVE: 'Save',
				GUEST_EMAIL: 'Guest Email',
				ENTER_EMAIL: 'Please enter the new e-mail address for this profile.',
				NOTE_PROFILE: "Note, it may take several minutes for the new email to update on the Guest's Profile",
				EMAIL_REQUIRED: 'Email is required.',
				INVALID_EMAIL: 'Invalid email address.',
				EDIT_STATUS: 'Edit Subscription Status',
				PLEASE_CONFIRM: 'Please confirm to change the subscription status for this guest profile',
				UNSUBSCRIBE: 'Unsubscribe',
				NOTE_UNDO: 'Note: You can not undo this action. Only the guest can subscribe again.',
				PROFILE: 'Profile',
				STAYS: 'Stays',
				ENGAGEMENT: 'Engagement',
				IMPLICIT: 'Implicit Consent',
				EXPLICIT: 'Explicit Consent',
				DOUBLE: 'Double Opt In',
				UNSUBSCRIBED: 'Unsubscribed',
			},
			REPORTS: {
				ONE_TIME: 'One-Time',
				STATS: 'Stats',
				REVENUE_DETAIL: 'Revenue Detail',	
				AUTOMATED: 'Automated',
				SEARCH_CAMPAIGN_NAME: 'Search by campaign name',
				SEARCH: 'Search',
				CLEAR: 'Clear',
				CAMPAIGN_NAME: 'Campaign Name',
				BOOKINGS: 'Bookings',
				SENT: 'Sent',
				OPENED: 'Opened',
				CLICKED: 'Clicked',
				LAST_MODIFIED: 'Last Modified',
				REPORT: 'Report',
				PREVIEW: 'Preview',
				NO_RESULTS: 'There were no results found',
				GUEST_SUMMARY: "Guest Summary",
				CATEGORY: "Category",
				GUEST_COUNT: "Guest Count",
				PERCENT_ALL_GUESTS: "Percent All Guests",
				REPEAT_GUEST_COUNT: "Repeat Guest Count",
				PERCENT_ALL_REPEAT_GUESTS: "Percent All Repeat Guests",
				REPEAT_GUEST_RATE: "Repeat Guest Rate",
				STAY_COUNT: "Stay Count",
				PERCENT_ALL_STAYS: "Percent All Stays",
				AVERAGE_STAY_NIGHTS: "Average Stay Nights",
				NIGHT_COUNT: "Night Count",
				PERCENT_ALL_NIGHTS: "Percent All Nights",
				REVENUE: "Revenue",
				PERCENT_ALL_REVENUE: "Percent All Revenue",
				AVERAGE_GUEST_REVENUE: "Average Guest Revenue",
				REPEAT_GUEST: "Repeat Guest",
				ONE_TIME_GUEST: "One-Time Guest",
				REPEAT_GUESTS: "Repeat Guests",
				LIFETIME_STAYS: "Lifetime Stays",
				TIME_SINCE_LAST_STAY: "Months Since Last Stay",
				LIFETIME_HOTELS: "Lifetime Hotels",
				FIRST_HOTEL: "First Hotel",
				LIFETIME_NIGHTS: "Lifetime Nights",
				LIFETIME_REVENUE: "Lifetime Revenue",
				LIFETIME_AVERAGE_REVENUE: "Lifetime Average Revenue",
				PAST_STAY_YEARS: "Past Stay Years",
				UNKNOWN: "Unknown",
				TOTAL: "Total",
				PRE: "Pre-"
			},
			CREDITS: 'E-mail Credits',
			SUB_HEADER: {
				QUIT: 'Quit',
				SAVE_QUIT: 'Save & Quit',
				SEND_TEST: 'Send a test',
				SCHEDULE: 'Schedule',
				ACTIVATE: 'Activate',
				CANCEL_SCHEDULE: 'Cancel Schedule',
				DEACTIVATE: 'Deactivate',
				NEXT_STEP: 'Next Step'	
			},
			CAMPAIGNS: {
				REVENUE: 'Revenue',
				SEARCH_PLACEHOLDER: 'Search name, email, reference',
				RESERVATIONS: 'Reservations',
				SENT: 'Sent',
				SEND: 'Send',
				OPENED: 'Opened',
				CLICKED: 'Clicked',
				DELIVERED: 'Delivered',
				BOUNCES: 'Bounces',
				UNSUBSCRIBE: 'Unsubscribe',
				SPAM_REPORTS: 'Spam Reports',
				DELIVERY_REPORT: 'Delivery Report',
				DOWNLOAD_REPORT: 'Download Report',
				DISPATCHED: 'Dispatched',
				GUEST: 'Guest',
				EMAIL: 'Email',
				STATUS: 'Status',
				NO_RESULTS: 'There were no results found',
				ROOM_NIGHTS: 'Room Nights',
				OPENS: 'Opens',
				CLICKS: 'Clicks',
				TOP_CHANNELS: 'Top Channels',
				TIME_SINCE_LAST_STAY: 'Time Since Last Stay',
				TOP_RATE_CODE_BY_CHANNEL: 'Top Rate Code by Channel',
				RATE_CODE: 'Rate Code',
				TOTAL: 'Total',
				BOOKING_LEAD_TIMES: 'Booking Lead Times',
				RESERVATION_REPORT: 'Reservation Report',
				BOOK_DATE: 'Book Date',
				SEND_DATE: 'Send Date',
				NIGHTS: 'Nights',
				BOOKING_CHANNEL: 'Booking Channel',
				TOTAL_RATE: 'Total Rate',
				REPORTS: 'Campaign Report',
				ONE_TIME: 'One-Time',
				AUTOMATED: 'Automated',
				CREATE_CAMPAIGN: 'Create Campaign',
				CREATE: 'Create',
				SEARCH_CAMPAIGN: 'Search by campaign name',
				SEARCH: 'Search',
				CLEAR: 'Clear',
				ALL_STATUSES: 'All Statuses',
				DRAFT: 'Draft',
				SCHEDULED: 'Scheduled',
				INACTIVE: 'Inactive',
				ACTIVE: 'Active',
				CAMPAIGN_NAME: 'Campaign Name',
				TYPE: 'Type',
				DELIVERY: 'Delivery',
				LAST_UPDATED: 'Last Updated',
				REPORT: 'Report',
				CANCEL_SCHEDULE: 'Cancel Schedule',
				DEACTIVATE: 'Deactivate',
				ACTIVATE: 'Activate',
				VIEW: 'View',
				DUPLICATE: 'Duplicate',
				DELETE: 'Delete',
				DELIVERING: 'Delivering',
				SUSPENDED: 'Suspended',
				REMOVE_CAMPAIGN: 'Remove Campaign',
				DO_YOU_REMOVE_CAMPAIGN: 'Are you sure you want to remove the campaign',
				CANCEL_CAMPAIGN: 'Cancel Campaign',
				DO_YOU_CANCEL_CAMPAIGN: 'Do you cancel the scheduled campaign',
				DEACTIVATE_CAMPAIGN: 'Deactivate Campaign',
				DO_YOU_DEACTIVATE_CAMPAIGN: 'Do you deactivate the campaign',
				ACTIVATE_CAMPAIGN: 'Activate Campaign',
				DO_YOU_ACTIVATE_CAMPAIGN: 'Do you activatate the campaign',
				SETUP: 'Setup',
				DESIGN: 'Design',
				SEGMENTS: 'Segments',
				SCHEDULE: 'Schedule',
				CONFIRMATION: 'Confirmation',
				SELECT: 'Select',
				ROOM_OFFERS: 'Room Offers',
				CAMPAIGNS_DESCR: 'Campaigns designed to increase room revenue through offers and packages',
				EVENTS_ANNOUNCEMENTS: 'Events & Announcements',
				NOTIFY_RECIPIENTS: 'Notify recipients of special events in the property',
				NEWSLETTER: 'Newsletter',
				CAMPAIGNS_UPDATE_DESCR: 'Campaigns to update on hotel news or events, sent regularly',
				MISS_YOU: 'We Miss You',
				CAMPAGINS_SENT: 'Campaigns sent to guests post-stay to encourage a rebooking',
				OTHER: 'Other',
				CREATE_CUSTOM: 'Create a custom campaign',
				PRE_ARRIVAL: 'Pre-Arrival',
				SEND_EMAIL: 'Send an e-mail to guests before they arrive',
				WELCOME: 'Welcome',
				SEND_EMAIL_CHECK_IN: 'Send an e-mail to guests upon check-in',
				QUALIFICATION: 'Qualification',
				SEND_EMAIL_SOON: 'Send an e-mail as soon as a guest enters a segment. They will only get sent this once',
				SENT_TO_GUESTS: 'Sent to guests post-stay to encourage a repeat visit',
				OTA_WINBACK: 'OTA Winback',
				SEND_CAMPAIGN: 'Send a campaign to OTA guests within 30 days after check-out',
				BIRTHDAY: 'Birthday',
				SEND_SPECIAL: 'Send a special offer to celebrate a birthday or anniversary',
				DOUBLE_OPT: 'Double Opt-In',
				AUTOMATICALLY_SEND: 'Automatically send a subscribe link to guests as they are added to a contact list',
				CAMPAIGN_NAME_HINT: 'Give your campaign an internal name to help organize and locate it easily within your account.',
				CAMPAIGN_NAME_REQUIRED: 'Campaign name is required',
				SUBJECT_LINE: 'Subject Line',
				SUBJECT_LINE_HINT: 'Write a subject line that clearly describes your email content. It will be visible in your recipient\'s inbox and is the first content they will see.',
				SUBJECT_REQUIRED: 'Subject is required',
				PREVIEW_LINE: 'Preview Line',
				PREVIEW_LINE_HINT: 'Write a short text (about 35 characters) that gives an overview your email. The text will be displayed in your recipient\'s inbox, just below the subject.',
				FROM_EMAIL: 'From Email',
				FROM_EMAIL_HINT: 'Choose the email address to be shown in your recipients inbox when they receive your campaign.',
				FROM_EMAIL_REQUIRED: 'From email is required',
				FROM_NAME: 'From Name',
				FROM_NAME_HINT: 'Enter a name (e.g. your company name) to help campaign recipients recognise you in their inbox.',
				FROM_NAME_REQUIRED: 'From name is required',
				MY_TEMPLATES: 'My Templates',
				TEMPLATE_GALLERY: 'Template Gallery',
				DESIGN_TOOLS: 'Design Tools',
				DRAG_DROP_EDITOR: 'Drag & Drop Editor',
				DRAG_DROP_EDITOR_DESC: 'Create a mobile-friendly email that looks great on any device or screen size',
				RICH_TEXT_EDITOR: 'Rich Text Editor',
				RICH_TEXT_EDITOR_DESC: 'Use the rich text editor to create simple emails',
				PASTE_YOUR_CODE: 'Paste your Code',
				PASTE_YOUR_CODE_DESC: 'Copy and paste your HTML code.',
				RESET_EMAIL_DESIGN: 'Reset email Design',
				EDIT_EMAIL_CONTENT: 'Edit email content',
				LOAD_SAVED_VERSION: 'Load a saved version',
				VERSION: 'Version',
				SAVE: 'Save',
				SAVE_QUIT: 'Save & Quit',
				QUIT_WITHOUT_SAVING: 'Quit without saving',
				HTML_EDITOR: 'HTML Editor',
				ENTER_HTML: 'Enter HTML',
				CANCEL: 'Cancel',
				CHOOSE_DIFFERENT_SEGMENT: 'Choose a different segment',
				ADDITIONAL_OPTIONS: 'Additional Options',
				FILTER_PROPERTIES: 'Filter Properties',
				ALL_PROPERTIES: 'All Properties',
				ENTER_PROPERTY_TO_FILTER: 'Enter property to filter...',
				ONLY_INCLUDE_GUESTS_WHO_HAVE: 'Only include guests who have',
				RECEIVED: 'received',
				NOT_RECEIVED: 'not received',
				OPENED_LOWER: 'opened',
				NOT_OPENED: 'not opened',
				CLICKED_LOWER: 'clicked',
				NOT_CLICKED: 'not clicked',
				BEEN_SENT: 'been sent',
				NOT_SENT: 'not been sent',
				ANY_EXISTING_CAMPAIGN_IN_LAST: 'any existing campaign in the last',
				DAY: 'day',
				WEEK: 'week',
				TWO_WEEKS: 'two weeks',
				MONTH: 'month',
				YEAR: 'year',
				TWO_YEARS: 'two years',
				ENABLE_SAFE_MODE: 'Enable Safe Mode',
				BEST_PRACTICE: 'GDPR best practice',
				GUESTS_MUST_HAVE: 'Guests must have',
				IMPLICIT_CONSENT: 'Implicit Consent',
				SAFE: 'safe',
				EXPLICIT_CONSENT: 'Explicit Consent',
				SAFER: 'safer',
				DOUBLE_OPT_IN_CONSENT: 'Double Opt-In Consent',
				SAFEST: 'safest',
				EXCLUDE_EMAIL_FUTURE_STAYS: 'Exclude email addresses with future stays',
				REFRESH_STATISTICS: 'Refresh Statistics',
				SELECT_SCHEDULE_OPTION: 'Please select a schedule option for your campaign:',
				SPECIFIC_DATE_TIME: 'A specific date and time',
				IMMEDIATELY: 'Immediately',
				BASED_ON_GUEST_EVENT: 'Based on a Guest Event',
				AT: 'at',
				HH: 'HH',
				MM: 'MM',
				ON: 'on',
				HOUR_REQUIRED: 'Hour is required',
				MINUTES_REQUIRED: 'Minutes are required',
				SELECT_DATE: 'Select a Date',
				DATE_REQUIRED: 'Date is required',
				DATE_MUST_BE_FUTURE: 'Date must be in the future',
				SELECT_DAYS: 'Select Days',
				DAYS_REQUIRED: 'Days is required',
				DAYS: 'days',
				SELECT_EVENT: 'Select Event',
				EVENT_REQUIRED: 'Event is required',
				SELECT_LIST: 'Select List',
				LIST_REQUIRED: 'List is required',
				CAMPAIGN_READY: 'Your campaign is ready to be sent!',
				REVIEW_REPORT_BEFORE_SENDING: 'Review the report below before sending your campaign',
				SUBJECT: 'Subject',
				FROM: 'From',
				ROOM_REVENUE_TRACKING: 'Room Revenue Tracking',
				DOUBLE_OPT_IN_URL: 'Double Opt-In URL',
				DOUBLE_OPT_IN_URL_HINT: 'This is the URL the user will be redirected to during a double opt-in flow.',
				DOUBLE_OPT_IN_URL_REQUIRED: 'Double opt-in URL is required',
				VALID_URL_REQUIRED: 'Please enter a valid URL',
				YOUR_DESIGN: 'Your Design',
				USED: 'You used the',
				CREATE_DESIGN: 'to create your design',
				EDIT_CONTENT: 'Edit the content',
				ADDITIONAL_FILTERS: 'Additional Filters',
				SEND_IMMEDIATELY: 'Send Immediately',
				SEND_TEST_EMAIL: 'Send Test E-mail',
				ENTER_EMAILS: 'Enter E-mails',
				ENTER_EMAILS_DESC: 'Enter a list of e-mail addresses, one per line',
				SEND_TEST: 'Send Test',
				SCHEDULE_CAMPAIGN: 'Schedule Campaign',
				SURE_DELIVERY: 'Are you sure you want to schedule your campaign for delivery?',
				CAMPAIGN_IN_LAST: ' any campaign in the last ',
				SEARCH_TEMPLATE_NAME: 'Search by template name',
				CREATE_TEMPLATE: 'Create Template',
				EDIT_CAMPAIGN: 'Edit Campaign',
				TEMPLATE_NAME: 'Template Name',
				EDIT_TEMPLATE: 'Edit template',
				MORE_OPTIONS: 'More options',
				TEMPLATE_NAME_HINT: 'Give your template an internal name to help organize and locate it easily within your account.',
				TEMPLATE_NAME_REQUIRED: 'Template name is required',
				LIST_SELECTED: 'List Selected',
				LISTS_SELECTED: ' Lists Selected',
				ALL_LISTS_SELECTED: 'All Lists Selected',
				BEFORE_CHECK_IN: 'before check in',
				AFTER_CHECK_IN: 'after check in',
				BEFORE_CHECK_OUT: 'before check out',
				AFTER_LAST_CHECK_OUT: 'after last check out',
				ON_CHECK_OUT: 'on check out',
				ON_CHECK_IN: 'on check in',
				ON_DAY_OF_CHECK_IN: 'on day of check in',
				BEFORE_BIRTHDAY: 'before birthday',
				BEFORE_ANNIVERSARY: 'before anniversary',
				ON_LIST_JOINED: 'on list joined',
				AT_SPASE: 'at ',
				UNLAYER_LANGUAGE: 'en-US',
				EDITOR_LANGUAGE: 'en',
			}
		},
		MENU: {
			NEW: 'new',
			ACTIONS: 'Actions',
			CREATE_POST: 'Create New Post',
			PAGES: 'Pages',
			FEATURES: 'Features',
			APPS: 'Apps',
			HOME: 'Home',
		},
		AUTH: {
			GENERAL: {
				OR: 'Or',
				SUBMIT_BUTTON: 'Submit',
				NO_ACCOUNT: 'Don\'t have an account?',
				SIGNUP_BUTTON: 'Sign Up',
				FORGOT_BUTTON: 'Forgot Password',
				BACK_BUTTON: 'Back',
				PRIVACY: 'Privacy',
				LEGAL: 'Legal',
				CONTACT: 'Contact',
			},
			LOGIN: {
				TITLE: 'Login Account',
				BUTTON: 'Sign In',
			},
			FORGOT: {
				TITLE: 'Forgotten Password?',
				DESC: 'Enter your email to reset your password',
				SUCCESS: 'Your account has been successfully reset.'
			},
			REGISTER: {
				TITLE: 'Sign Up',
				DESC: 'Enter your details to create your account',
				SUCCESS: 'Your account has been successfuly registered.'
			},
			INPUT: {
				EMAIL: 'E-mail',
				FULLNAME: 'Name',
				PASSWORD: 'Password',
				CONFIRM_PASSWORD: 'Confirm Password',
				USERNAME: 'Username'
			},
			VALIDATION: {
				INVALID: '{{name}} is not valid',
				REQUIRED: '{{name}} is required',
				MIN_LENGTH: '{{name}} minimum length is {{min}}',
				AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
				NOT_FOUND: 'The requested {{name}} is not found',
				INVALID_LOGIN: 'The login details are incorrect',
				REQUIRED_FIELD: 'Required field',
				MIN_LENGTH_FIELD: 'Minimum field length:',
				MAX_LENGTH_FIELD: 'Maximum field length:',
				INVALID_FIELD: 'Field is not valid',
			}
		},
		ECOMMERCE: {
			COMMON: {
				SELECTED_RECORDS_COUNT: 'Selected records count: ',
				ALL: 'All',
				SUSPENDED: 'Suspended',
				ACTIVE: 'Active',
				FILTER: 'Filter',
				BY_STATUS: 'by Status',
				BY_TYPE: 'by Type',
				BUSINESS: 'Business',
				INDIVIDUAL: 'Individual',
				SEARCH: 'Search',
				IN_ALL_FIELDS: 'in all fields'
			},
			ECOMMERCE: 'eCommerce',
			CUSTOMERS: {
				CUSTOMERS: 'Customers',
				CUSTOMERS_LIST: 'Customers list',
				NEW_CUSTOMER: 'New Customer',
				DELETE_CUSTOMER_SIMPLE: {
					TITLE: 'Customer Delete',
					DESCRIPTION: 'Are you sure to permanently delete this customer?',
					WAIT_DESCRIPTION: 'Customer is deleting...',
					MESSAGE: 'Customer has been deleted'
				},
				DELETE_CUSTOMER_MULTY: {
					TITLE: 'Customers Delete',
					DESCRIPTION: 'Are you sure to permanently delete selected customers?',
					WAIT_DESCRIPTION: 'Customers are deleting...',
					MESSAGE: 'Selected customers have been deleted'
				},
				UPDATE_STATUS: {
					TITLE: 'Status has been updated for selected customers',
					MESSAGE: 'Selected customers status have successfully been updated'
				},
				EDIT: {
					UPDATE_MESSAGE: 'Customer has been updated',
					ADD_MESSAGE: 'Customer has been created'
				}
			}
		},
		Jan: 'Jan',
		Feb: 'Feb',
		Mar: 'Mar',
		Apr: 'Apr',
		May: 'May',
		Jun: 'Jun',
		Jul: 'Jul',
		Aug: 'Aug',
		Sep: 'Sept',
		Oct: 'Oct',
		Nov: 'Nov',
		Dec: 'Dec',
		Clicks: 'Clicks',
		Opens: 'Opens',
		'< 7 days': '< 7 days',
		'7-14 Days': '7-14 Days',
		'15-30 Days': '15-30 Days',
		'31-60 Days': '31-60 Days',
		'61-90 Days': '61-90 Days',
		'> 90 Days': '> 90 Days',
		'< 1 month': '< 1 month',
		'1-2 Months': '1-2 Months',
		'2-6 Months': '2-6 Months',
		'6-12 Months': '6-12 Months',
		'> 1 Year': '> 1 Year',
		Sent: 'Sent',
		Delivered: 'Delivered',
		Open: 'Open',
		Spam: 'Spam',
		Click: 'Click',
		HardBounce: 'Hard Bounce',
		SoftBounce: 'Soft Bounce',
		Unsubscribe: 'Unsubscribe',
		InvalidEmail: 'Invalid Email',
		Blocked: 'Blocked',
		Complaint: 'Complaint',
		Deferred: 'Deferred',
		ListAddition: 'List Addition',
		Overview: 'Overview',
		"Guest List": 'Guest List',
		"Guest Lists": 'Guest Lists',
		"Guest Segments" : 'Guest Segments',
		Campaigns: 'Campaigns',
		Templates: 'Templates',
	}
};
