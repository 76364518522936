import { L } from "@angular/cdk/keycodes";

// USA
export const locale = {
  lang: "de",
  data: {
    TRANSLATOR: {
      SELECT: "Wählen Sie Ihre Sprache aus"
    },
    USER_PROFILE: {
      SETTINGS: "Einstellungen",
      HOTEL_OPTIONS: "Hoteloptionen und Einrichtung",
      HELP: "Hilfe & Support",
      HELP_GUID: "Vollständige Anleitung und Unterstützung",
      THEME: "Design",
      LANGUAGE: "Sprache",
      SIGN_OUT: "Abmelden",
      USERS: "Nutzer",
      MANAGE_USERS: "Nutzer- und Hotelberechtigungen verwalten"
    },
    CONNECTS: {
      MENU: {
        OVERVIEW: "Übersicht",
        GUESTS_SEGMENTS: "Gäste & Segmente",
        GUESTS: "Gäste",
        SEGMENTS: "Segmente",
        LISTS: "Listen",
        CAMPAIGNS: "Kampagnen",
        CAMPAIGN_LIST: "Kampagnenliste",
        TEMPLATES: "Vorlagen",
        REPORTS: "Berichte"
      },
      OVERVIEW: {
        TOTAL: "Profile gesamt",
        REACHABLE: "Erreichbare Profile",
        UNREACHABLE: "Unerreichbare Profile",
        EMAIL_COVERAGE: "E-Mail-Abdeckung",
        CAMPAIGN: "Zimmer-Kampagnenumsatz",
        YEAR: "Jahr bis heute",
        ONE_TIME: "Einmaliger Kampagnenumsatz",
        LAST: "Letzte 5 Umsätze",
        ALL: "Alle anzeigen",
        LIFETIME: "Allzeitumsatz",
        REVENUE: "Gesamtumsatz",
        ARRIVALS: "Anreisen",
        NUMBER_OF_GUESTS: "Anzahl der Gäste",
        ROOM_REVENUE: "Zimmerumsatz",
        NAME: 'Name',
        NIGHTS: "Nächte",
        LIFETIME_VALUE: "Allzeitwert",
        EMAIL: "E-Mail",
        DEPARTURES: "Abreisen"
      },
      GUESTS: {
        ALL_GUESTS: "Alle Gäste",
        ARRIVALS: "Anreisen",
        HOUSE: "Anwesend",
        DEPARTURES: "Abreisen",
        SEARCH_PLACEHOLDER: "Name, E-Mail-Adresse, Referenz suchen",
        DATE_PLACEHOLDER: "Datumsbereich auswählen",
        FILTERS: "Filter",
        CHANGE_HOTEL: "Hotel ändern",
        SEARCH: "Suchen",
        CLEAR: "Zurücksetzen",
        GUEST: "Gast",
        NIGHTS: "Nächte",
        ROOM_TYPE: "Zimmertyp",
        REVENUE: "Umsatz",
        BOOKING: "Buchungskanal",
        RATE: "Preiscode",
        EMAIL: "E-Mail-Adresse",
        PREVIOUS: "Vorherige Aufenthalte",
        LIFETIME: "Allzeitwert",
        NOTES: "Anmerkungen",
        NO_RESULTS: "Es wurden keine Ergebnisse gefunden",
        ITEMS: "Einträge pro Seite:"
      },
      SEGMENTS: {
        SEARCH_SEGMENTS: "Suche nach Segmentname",
        CREATE_SEGMENT: "Gastsegment erstellen",
        EDIT_SEGMENT: "Gastsegment bearbeiten",
        REFRESH: "Jetzt aktualisieren",
        STATISTICS: "Statistik zuletzt aktualisiert",
        NO_RESULTS: "Es wurden keine Ergebnisse gefunden",
        REACHABLE: "Erreichbar per E-Mail",
        PROFILES: "Profile",
        AVARAGE_NIGHTS: "Durchschnittliche Nächte",
        AVARAGE_RATE: "Durchschnittlicher Preis pro Tag",
        OR: "ODER",
        EXCLUDE_BOOKINGS: "Buchungen mit künftigen Aufenthalten ausschließen",
        VIEW_DETAILS: "Details anzeigen",
        SELECT: "Auswählen",
        PEOPLE_IN_SEGMENT: "Personen in diesem Segment",
        TOTAL_LIFETIME: "Allzeitausgaben",
        SEARCH_PLACEHOLDER: "Name, E-Mail-Adresse, Referenz suchen",
        SEARCH: "Suchen",
        CLEAR: "Zurücksetzen",
        GUEST: "Gast",
        EMAIL: "E-Mail",
        LIFETIME_VALUE: "Allzeitwert",
        LIFETIME_STAYS: "Allzeitaufenthalte",
        TOTAL_NIGHTS: "Nächte gesamt",
        CLOSE: "Schließen",
        ITEMS: "Einträge pro Seite:",
        SEGMENT_NAME: "Segmentname",
        SEGMENT_REQUIRED: "Segmentname ist erforderlich",
        CONTACT_LISTS: "Kontaktlisten",
        ENTER_CONTACT: "Kontaktliste eingeben …",
        PROFILES_SEGMENT: "Profile in diesem Segment",
        TOTAL_LIFETIME_UPPER: "Allzeitausgaben",
        REFRESH_STATICITCS: "Statistik aktualisieren",
        FIND_GUESTS: "Gäste finden, die folgende Bedingungen erfüllen",
        AND: "UND",
        SEARCH_VALUE: "Suchbegriff eingeben",
        ENTER_DISTANCE: "Distanz eingeben",
        IMPLICIT_CONSENT: "Implizite Zustimmung (sicher)",
        EXPLICIT_CONSENT: "Explizite Zustimmung (noch sicher)",
        DOUBLE_CONSENT: "Doppelte Anmeldezustimmung (am sichersten)",
        ENTER_DATE: "Datumsbereich eingeben",
        START_DATE: "Startdatum",
        END_DATE: "Enddatum",
        START_DAYS: "Starttage",
        END_DAYS: "Endtage",
        LAST_DAYS: "Letzte Tage",
        EXCLUDE_GUESTS: "Gäste mit künftigen Aufenthalten ausschließen",
        CORRECT_FILTERS: "Bitte korrigieren Sie die Fehler in den Segmentfiltern",
        CANCEL: "Abbrechen",
        SAVE: "Speichern",
        BETWEEN_DATES: "zwischen Tagen",
        BETWEEN_FUTURE: "zwischen künftigen Tagen",
        DAYS: "Tage",
        BETWEEN_PAST: "zwischen vergangenen Tagen",
        LAST: "in den letzten",
        EQUAL: "ist gleich",
        NOT_EQUAL: "ist ungleich",
        GREATER: "größer als",
        LESS: "weniger als",
        KM: 'km',
        CONTAINS: "enthält",
        NOT_CONTAINS: "enthält nicht",
        IS_EQUAL: "ist gleich",
        IS_NOT_EQUAL: "ist ungleich",
        LEAST: "hat mindestens",
        ANNIVERSARY: "Jahrestag",
        BADGE: "Abzeichen",
        BIRTHDAY: "Geburtstag",
        COMPANY: "Firma",
        EMAIL_ADDRESS: "E-Mail-Adresse",
        GENDER: "Geschlecht",
        LANGUAGE: "Sprache",
        LIFETIME_ROOM: "Allzeit-Zimmerausgaben",
        LOCATION: "Standort",
        TOTAL_NUMBER_NIGHTS: "Gesamtzahl der Nächte",
        TOTAL_NUMBER_STAYS: "Gesamtzahl der Aufenthalte",
        SUBSCRIPTION: "Abonnementstatus",
        BOOKING: "Buchungskanal",
        BOOKING_DATE: "Buchungsdatum",
        BOOKING_LEAD: "Buchungsvorlaufzeit",
        CHECK_IN_DATE: "Check-in-Datum",
        CHECK_OUT_DATE: "Check-out-Datum",
        LENGTH: "Aufenthaltsdauer",
        MARKET: "Marktcode",
        RATE: "Preiscode",
        ROOM: "Zimmercode",
        RATE_AVARAGE: "Durchschnittspreis",
        SOURCE: "Geschäftsquelle",
        STAY: "Aufenthaltsdatum",
        DEMOGRAPHICS: "Demografie",
        MARKETING: "E-Mail-Marketing",
        STAY_DATA: "Aufenthaltsdaten",
        IMPORT_GUESTS: "Gäste importieren",
        ADD_LIST: "Liste hinzufügen",
        HOTEL_NAME: "Hotelname",
        GROUPWIDE: "Gruppenweit",
        NAME: 'Name',
        GUESTS: "Gäste",
        EDIT_LIST: "Liste bearbeiten",
        UPDATE_LIST: "Liste aktualisieren",
        LIST_NAME: "Listenname",
        ROOM_REQUIRED: "Name des Zimmertyps ist erforderlich",
        SELECT_HOTEL: "Hotel auswählen",
        SELECT_DATA: "Daten auswählen",
        SELECT_LIST: "Liste auswählen",
        PLEASE_SELECT_LIST: "Bitte wählen Sie eine Liste aus",
        SUBSCRIPTION_STATUS: "Abonnementstatus",
        UNSUBSCRIBED: "Nicht abonniert",
        IMPLICIT: "Implizit",
        EXPLICIT: "Explizit",
        DOUBLE_OPT_IN: "Doppelte Anmeldung",
        PLEASE_SELECT_SUBSCRIPTION: "Bitte wählen Sie einen Abonnementstatus",
        PLEASE_UPLOAD_FILE: "Bitte laden Sie eine Datei hoch",
        DOWNLOAD_EXAMPLE: "Laden Sie eine CSV-Datei als Beispiel herunter",
        NEXT: "Weiter",
        COMPLETE_MAPPINGS: "Vollständige Zuweisungen",
        BACK: "Zurück",
        IMPORT: "Importieren",
        ERROR_UPLOADING: "Beim Hochladen der Gäste trat leider ein Fehler auf",
        UPLOAD_FILE: "Datei hochladen",
        NO_FILE: "Keine Datei ausgewählt"
      },
      GUESTS_PROFILE: {
        LEAD_TIME: "Vorlaufzeit",
        LIFETIME_STAYS: "Allzeitaufenthalte",
        LIFETIME_NIGHTS: "Allzeitnächte",
        LIFETIME_SPEND: "Allzeitausgaben",
        STATUS: 'Status',
        HOUSE: "Anwesend",
        LAST_STAY: "Letzter Aufenthalt",
        NEXT_STAY: "Nächster Aufenthalt",
        NIGHTS: "Nächte",
        TODAY: "Heute",
        PERSONAL: "Persönliches",
        TITLE: "Titel",
        FIRST_NAME: "Vorname",
        LAST_NAME: "Nachname",
        GENDER: "Geschlecht",
        LANGUAGE: "Sprache",
        COMPANY: "Firma",
        JOB_TITLE: "Jobtitel",
        ADDRESS_1: "Adresse 1",
        ADDRESS_2: "Adresse 2",
        CITY_COUNTY: "Stadt, Bezirk",
        POST_CODE: "Postleitzahl",
        COUNTRY: "Land",
        OTHER: "Sonstiges",
        BIRTHDAY: "Geburtstag",
        ANNIVERSARY: "Jahrestag",
        PROFILE_NOTES: "Profilanmerkungen",
        EMAIL: "E-Mail-Adresse",
        SUBSCRIPTION_STATUS: "Abonnementstatus",
        BADGES: "Abzeichen",
        CONTACT_LISTS: "Kontaktlisten",
        BOOKING_NOTES: "Buchungsanmerkungen",
        LOYALTY: "Treue",
        FIRST_STAY: "Erster Aufenthalt mit Gruppe",
        LIFETIME_VALID_STAYS: "Gültige Aufenthalte allzeit",
        LIFETIME_CANCELLED: "Stornierte Aufenthalte allzeit",
        LIFETIME_VALID_NIGHTS: "Gültige Nächte allzeit",
        CURRENT_STAY: "Aktueller Aufenthalt",
        NO_STAYS: "Keine künftigen Aufenthalte",
        CHECK_IN: "Check-in",
        CHECK_OUT: "Check-out",
        OF_NIGHTS: "# Nächte",
        BOOKING_REF: "Buchungsreferenz",
        HOTEL: 'Hotel',
        ROOM_TYPE: "Zimmertyp",
        RATE: "Preis",
        CHANNEL: "Kanal",
        GUESTS: "Gäste",
        ROOM: "Zimmer",
        BOOKING_DATE: "Buchungsdatum",
        NIGTH: "Nacht",
        ALL_STAYS: "Alle Aufenthalte",
        CANCELLED: "Storniert",
        IN_HOUSE: "Anwesend",
        GUEST_MARKETING: "Gästemarketing",
        DATE_SENT: "Daten gesendet",
        EVENT_DATE: "Ereignisdatum",
        CAMPAIGN: "Kampagne",
        PENDING_REQUESTS: "Ausstehende Anfragen",
        DATE: "Datum",
        READ_ON: "Gelesen am",
        REQUESTS: "Anfragen",
        TYPE: "Typ",
        READ: "Gelesen",
        UNREAD: "Ungelesen",
        ADD_BADGE: "Abzeichen hinzufügen",
        BADGE: "Abzeichen",
        CANCEL: "Abbrechen",
        SAVE: "Speichern",
        GUEST_EMAIL: "E-Mail-Adresse des Gastes",
        ENTER_EMAIL: "Bitte geben Sie die neue E-Mail-Adresse für dieses Profil ein.",
        NOTE_PROFILE: "Beachten Sie, dass es einige Minuten dauern kann, bis die neue E-Mail-Adresse im Profil des Gastes aktualisiert wird",
        EMAIL_REQUIRED: "E-Mail-Adresse ist erforderlich.",
        INVALID_EMAIL: "Ungültige E-Mail-Adresse.",
        EDIT_STATUS: "Abonnementstatus bearbeiten",
        PLEASE_CONFIRM: "Bitte bestätigen Sie, um den Abonnementstatus für dieses Gastprofil zu ändern",
        UNSUBSCRIBE: "Abmelden",
        NOTE_UNDO: "Hinweis: Sie können diese Aktion nicht rückgängigmachen. Nur der Gast kann erneut abonnieren.",
        PROFILE: "Profil",
        STAYS: "Aufenthalte",
        ENGAGEMENT: "Interaktion",
        IMPLICIT: "Implizite Zustimmung",
        EXPLICIT: "Explizite Zustimmung",
        DOUBLE: "Doppelte Anmeldung",
        UNSUBSCRIBED: "Nicht abonniert"
      },
      REPORTS: {
        ONE_TIME: "Einmalig",
        STATS: "Statistik",
        REVENUE_DETAIL: "Umsatzdetails",
        AUTOMATED: "Automatisiert",
        SEARCH_CAMPAIGN_NAME: "Suche nach Kampagnenname",
        SEARCH: "Suchen",
        CLEAR: "Zurücksetzen",
        CAMPAIGN_NAME: "Kampagnenname",
        BOOKINGS: "Buchungen",
        SENT: "Gesendet",
        OPENED: "Geöffnet",
        CLICKED: "Geklickt",
        LAST_MODIFIED: "Zuletzt geändert",
        REPORT: "Bericht",
        PREVIEW: "Vorschau",
        NO_RESULTS: "Es wurden keine Ergebnisse gefunden"
      },
      CREDITS: "E-Mail-Guthaben",
      SUB_HEADER: {
        QUIT: "Beenden",
        SAVE_QUIT: "Speichern & Beenden",
        SEND_TEST: "Test senden",
        SCHEDULE: "Planen",
        ACTIVATE: "Aktivieren",
        CANCEL_SCHEDULE: "Plan stornieren",
        DEACTIVATE: "Deaktivieren",
        NEXT_STEP: "Nächster Schritt"
      },
      CAMPAIGNS: {
        REVENUE: "Umsatz",
        SEARCH_PLACEHOLDER: "Name, E-Mail-Adresse, Referenz suchen",
        RESERVATIONS: "Reservierungen",
        SENT: "Gesendet",
        OPENED: "Geöffnet",
        CLICKED: "Geklickt",
        DELIVERED: "Zugestellt",
        BOUNCES: 'Bounces',
        UNSUBSCRIBE: "Abmelden",
        SPAM_REPORTS: "Spam-Berichte",
        DELIVERY_REPORT: "Zustellbericht",
        DOWNLOAD_REPORT: "Bericht herunterladen",
        DISPATCHED: "Versendet",
        GUEST: "Gast",
        EMAIL: "E-Mail",
        STATUS: 'Status',
        NO_RESULTS: "Es wurden keine Ergebnisse gefunden",
        ROOM_NIGHTS: "Zimmernächte",
        OPENS: "Geöffnet",
        CLICKS: "Geklickt",
        TOP_CHANNELS: "Top-Kanäle",
        TIME_SINCE_LAST_STAY: "Zeit seit letztem Aufenthalt",
        TOP_RATE_CODE_BY_CHANNEL: "Top-Preiscode nach Kanal",
        RATE_CODE: "Preiscode",
        TOTAL: "Gesamt",
        BOOKING_LEAD_TIMES: "Buchungsvorlaufzeiten",
        RESERVATION_REPORT: "Reservierungsbericht",
        BOOK_DATE: "Datum buchen",
        SEND_DATE: "Datum senden",
        NIGHTS: "Nächte",
        BOOKING_CHANNEL: "Buchungskanal",
        TOTAL_RATE: "Gesamtpreis",
        REPORTS: "Kampagnenbericht",
        ONE_TIME: "Einmalig",
        AUTOMATED: "Automatisiert",
        CREATE_CAMPAIGN: "Kampagne erstellen",
        CREATE: "Erstellen",
        SEARCH_CAMPAIGN: "Suche nach Kampagnenname",
        SEARCH: "Suchen",
        CLEAR: "Zurücksetzen",
        ALL_STATUSES: "Alle Status",
        DRAFT: "Entwurf",
        SCHEDULED: "Geplant",
        INACTIVE: "Inaktiv",
        ACTIVE: "Aktiv",
        CAMPAIGN_NAME: "Kampagnenname",
        TYPE: "Typ",
        DELIVERY: "Zustellung",
        LAST_UPDATED: "Zuletzt aktualisiert",
        REPORT: "Bericht",
        CANCEL_SCHEDULE: "Plan stornieren",
        DEACTIVATE: "Deaktivieren",
        ACTIVATE: 'Aktivieren',
        VIEW: "Anzeigen",
        DUPLICATE: "Duplizieren",
        DELETE: "Löschen",
        DELIVERING: "Zustellung",
        SUSPENDED: "Ausgesetzt",
        REMOVE_CAMPAIGN: "Kampagne entfernen",
        DO_YOU_REMOVE_CAMPAIGN: "Sind Sie sicher, dass Sie die Kampagne entfernen möchten",
        CANCEL_CAMPAIGN: "Kampagne abbrechen",
        DO_YOU_CANCEL_CAMPAIGN: "Brechen Sie die geplante Kampagne ab",
        DEACTIVATE_CAMPAIGN: "Kampagne deaktivieren",
        DO_YOU_DEACTIVATE_CAMPAIGN: "Deaktivieren Sie die Kampagne",
        ACTIVATE_CAMPAIGN: "Kampagne aktivieren",
        DO_YOU_ACTIVATE_CAMPAIGN: "Aktivieren Sie die Kampagne",
        SETUP: "Einrichtung",
        DESIGN: 'Design',
        SEGMENTS: "Segmente",
        SCHEDULE: "Plan",
        CONFIRMATION: "Bestätigung",
        SELECT: "Auswählen",
        ROOM_OFFERS: "Zimmerangebote",
        CAMPAIGNS_DESCR: "Kampagnen zur Steigerung des Zimmerumsatzes durch Angebote und Pakete",
        EVENTS_ANNOUNCEMENTS: "Ereignisse & Ankündigungen",
        NOTIFY_RECIPIENTS: "Benachrichtigen Sie die Empfänger über besondere Ereignisse in der Anlage",
        NEWSLETTER: 'Newsletter',
        CAMPAIGNS_UPDATE_DESCR: "Kampagnen, um regelmäßig über Hotelneuigkeiten oder Ereignisse zu informieren",
        MISS_YOU: "Sie fehlen uns",
        CAMPAGINS_SENT: "Kampagnen, die an Gäste nach dem Aufenthalt gesendet werden, um eine erneute Buchung zu fördern",
        OTHER: "Sonstiges",
        CREATE_CUSTOM: "Benutzerdefinierte Kampagne erstellen",
        PRE_ARRIVAL: "Vor Ankunft",
        SEND_EMAIL: "Senden Sie eine E-Mail an Ihre Gäste, bevor sie ankommen",
        WELCOME: "Willkommen",
        SEND_EMAIL_CHECK_IN: "Senden Sie eine E-Mail an Gäste beim Check-in",
        QUALIFICATION: "Qualifizierung",
        SEND_EMAIL_SOON: "Senden Sie eine E-Mail, sobald ein Gast ein Segment betritt. Gäste erhalten diese E-Mail nur einmal",
        SENT_TO_GUESTS: "Wird dem Aufenthalt an die Gäste gesendet, um einen erneuten Besuch zu fördern",
        OTA_WINBACK: "OTA-Rückgewinnung",
        SEND_CAMPAIGN: "Senden Sie eine Kampagne an OTA-Gäste innerhalb von 30 Tagen nach dem Check-out",
        BIRTHDAY: "Geburtstag",
        SEND_SPECIAL: "Senden Sie ein spezielles Angebot zu einem Geburtstag oder Jahrestag",
        DOUBLE_OPT: "Doppelte Anmeldung",
        AUTOMATICALLY_SEND: "Senden Sie automatisch einen Link zum Abonnieren an Gäste, wenn diese zu einer Kontaktliste hinzugefügt werden",
        CAMPAIGN_NAME_HINT: "Geben Sie Ihrer Kampagne einen internen Namen, um sie innerhalb Ihres Kontos leichter zu organisieren und zu finden.",
        CAMPAIGN_NAME_REQUIRED: "Kampagnenname ist erforderlich",
        SUBJECT_LINE: "Betreffzeile",
        SUBJECT_LINE_HINT: "Verfassen Sie eine Betreffzeile, die den Inhalt Ihrer E-Mail klar beschreibt. Sie wird im Posteingang Ihres Empfängers angezeigt und ist der erste Inhalt, den er sieht.",
        SUBJECT_REQUIRED: "Betreff ist erforderlich",
        PREVIEW_LINE: "Vorschauzeile",
        PREVIEW_LINE_HINT: "Schreiben Sie einen kurzen Text (etwa 35 Zeichen), der einen Überblick über Ihre E-Mail gibt. Der Text wird im Posteingang des Empfängers angezeigt, direkt unter dem Betreff.",
        FROM_EMAIL: "Absender-E-Mail-Adresse",
        FROM_EMAIL_HINT: "Wählen Sie die E-Mail-Adresse, die im Posteingang des Empfängers angezeigt werden soll, wenn er Ihre Kampagne erhält.",
        FROM_EMAIL_REQUIRED: "Absender-E-Mail-Adresse ist erforderlich",
        FROM_NAME: "Absendername",
        FROM_NAME_HINT: "Geben Sie einen Namen ein (z. B. den Namen Ihres Unternehmens), damit die Empfänger der Kampagne Sie in ihrem Posteingang wiedererkennen.",
        FROM_NAME_REQUIRED: "Absendername ist erforderlich",
        MY_TEMPLATES: "Meine Vorlagen",
        TEMPLATE_GALLERY: "Vorlagengalerie",
        DESIGN_TOOLS: "Designtools",
        DRAG_DROP_EDITOR: 'Drag & Drop Editor',
        DRAG_DROP_EDITOR_DESC: "Erstellen Sie eine mobilfreundliche E-Mail, die auf jedem Gerät und jeder Bildschirmgröße gut aussieht",
        RICH_TEXT_EDITOR: 'Rich Text Editor',
        RICH_TEXT_EDITOR_DESC: "Verwenden Sie den Rich Text Editor, um einfache E-Mails zu erstellen",
        PASTE_YOUR_CODE: "Code einfügen",
        PASTE_YOUR_CODE_DESC: "Kopieren und fügen Sie Ihren HTML-Code ein.",
        RESET_EMAIL_DESIGN: "E-Mail-Design zurücksetzen",
        EDIT_EMAIL_CONTENT: "E-Mail-Inhalt bearbeiten",
        LOAD_SAVED_VERSION: "Eine gespeicherte Version laden",
        VERSION: 'Version',
        SAVE: "Speichern",
        SAVE_QUIT: "Speichern & Beenden",
        QUIT_WITHOUT_SAVING: "Beenden ohne Speichern",
        HTML_EDITOR: "HTML-Editor",
        ENTER_HTML: "HTML eingeben",
        CANCEL: "Abbrechen",
        CHOOSE_DIFFERENT_SEGMENT: "Ein anderes Segment auswählen",
        ADDITIONAL_OPTIONS: "Zusätzliche Optionen",
        FILTER_PROPERTIES: "Eigenschaften filtern",
        ALL_PROPERTIES: "Alle Eigenschaften",
        ENTER_PROPERTY_TO_FILTER: "Eigenschaft zum Filtern eingeben …",
        ONLY_INCLUDE_GUESTS_WHO_HAVE: "Nur Gäste einschließen mit",
        RECEIVED: "erhaltener",
        NOT_RECEIVED: "nicht erhaltener",
        OPENED_LOWER: "geöffneter",
        NOT_OPENED: "nicht geöffneter",
        CLICKED_LOWER: "geklickter",
        NOT_CLICKED: "nicht geklickter",
        BEEN_SENT: "gesendeter",
        NOT_SENT: "nicht gesendeter",
        ANY_EXISTING_CAMPAIGN_IN_LAST: "Kampagne i. d. letzten",
        DAY: "Tag",
        WEEK: "Woche",
        TWO_WEEKS: "zwei Wochen",
        MONTH: "Monat",
        YEAR: "Jahr",
        TWO_YEARS: "zwei Jahren",
        ENABLE_SAFE_MODE: "Abgesicherten Modus aktivieren",
        BEST_PRACTICE: "Best Practice für DSGVO",
        GUESTS_MUST_HAVE: "Gäste brauchen",
        IMPLICIT_CONSENT: "Implizite Zustimmung",
        SAFE: "sicher",
        EXPLICIT_CONSENT: "Explizite Zustimmung",
        SAFER: "sicherer",
        DOUBLE_OPT_IN_CONSENT: "Doppelte Anmeldezustimmung",
        SAFEST: "am sichersten",
        EXCLUDE_EMAIL_FUTURE_STAYS: "E-Mail-Adressen mit künftigen Aufenthalten ausschließen",
        REFRESH_STATISTICS: "Statistik aktualisieren",
        SELECT_SCHEDULE_OPTION: "Bitte wählen Sie eine Planoption für Ihre Kampagne:",
        SPECIFIC_DATE_TIME: "Bestimmtes Datum und bestimmte Uhrzeit",
        IMMEDIATELY: "Sofort",
        BASED_ON_GUEST_EVENT: "Basierend auf Gastereignis",
        AT: "um",
        HH: 'HH',
        MM: 'MM',
        ON: "am",
        HOUR_REQUIRED: "Stunde ist erforderlich",
        MINUTES_REQUIRED: "Minuten sind erforderlich",
        SELECT_DATE: "Datum auswählen",
        DATE_REQUIRED: "Datum ist erforderlich",
        DATE_MUST_BE_FUTURE: "Datum muss in der Zukunft liegen",
        SELECT_DAYS: "Tage auswählen",
        DAYS_REQUIRED: "Tage sind erforderlich",
        DAYS: "Tage",
        SELECT_EVENT: "Ereignis auswählen",
        EVENT_REQUIRED: "Ereignis ist erforderlich",
        SELECT_LIST: "Liste auswählen",
        LIST_REQUIRED: "Liste ist erforderlich",
        CAMPAIGN_READY: "Ihre Kampagne ist bereit zum Versenden!",
        REVIEW_REPORT_BEFORE_SENDING: "Prüfen Sie den folgenden Bericht, bevor Sie Ihre Kampagne versenden",
        SUBJECT: "Betreff",
        FROM: "Von",
        ROOM_REVENUE_TRACKING: "Zimmerumsatz-Tracking",
        DOUBLE_OPT_IN_URL: "URL für doppelte Anmeldung",
        DOUBLE_OPT_IN_URL_HINT: "Dies ist die URL, an die der Benutzer während eines doppelten Anmeldevorgangs weitergeleitet wird.",
        DOUBLE_OPT_IN_URL_REQUIRED: "URL für doppelte Anmeldung ist erforderlich",
        VALID_URL_REQUIRED: "Bitte geben Sie eine gültige URL ein",
        YOUR_DESIGN: "Ihr Design",
        USED: "Sie verwendeten",
        CREATE_DESIGN: "für die Erstellung Ihres Designs",
        EDIT_CONTENT: "Inhalt bearbeiten",
        ADDITIONAL_FILTERS: "Zusätzliche Filter",
        SEND_IMMEDIATELY: "Sofort senden",
        SEND_TEST_EMAIL: "Test-E-Mail senden",
        ENTER_EMAILS: "E-Mail-Adressen eingeben",
        ENTER_EMAILS_DESC: "Geben Sie eine Liste von E-Mail-Adressen ein, eine pro Zeile",
        SEND_TEST: "Test senden",
        SCHEDULE_CAMPAIGN: "Kampagne planen",
        SURE_DELIVERY: "Sind Sie sicher, dass Sie Ihre Kampagne für die Zustellung planen möchten?",
        CAMPAIGN_IN_LAST: " Kampagne i. d. letzten ",
        SEARCH_TEMPLATE_NAME: "Suche nach Vorlagenname",
        CREATE_TEMPLATE: "Vorlage erstellen",
        EDIT_CAMPAIGN: "Kampagne bearbeiten",
        TEMPLATE_NAME: "Vorlagenname",
        EDIT_TEMPLATE: "Vorlage bearbeiten",
        MORE_OPTIONS: "Weitere Optionen",
        TEMPLATE_NAME_HINT: "Geben Sie Ihrer Vorlage einen internen Namen, um sie innerhalb Ihres Kontos leichter zu organisieren und zu finden.",
        TEMPLATE_NAME_REQUIRED: "Vorlagenname ist erforderlich",
        LIST_SELECTED: "Liste ausgewählt",
        LISTS_SELECTED: " Listen ausgewählt",
        ALL_LISTS_SELECTED: "Alle Listen ausgewählt",
        BEFORE_CHECK_IN: "vor Check-in",
        AFTER_CHECK_IN: "nach Check-in",
        BEFORE_CHECK_OUT: "vor Check-out",
        AFTER_LAST_CHECK_OUT: "nach letztem Check-out",
        ON_CHECK_OUT: "bei Check-out",
        ON_CHECK_IN: "bei Check-in",
        ON_DAY_OF_CHECK_IN: "am Tag des Check-ins",
        BEFORE_BIRTHDAY: "vor dem Geburtstag",
        BEFORE_ANNIVERSARY: "vor dem Jahrestag",
        ON_LIST_JOINED: "bei Listenbeitritt",
        AT_SPASE: "um ",
        UNLAYER_LANGUAGE: "de-DE",
        EDITOR_LANGUAGE: "de"
      }
    },
    MENU: {
      NEW: "neu",
      ACTIONS: "Aktionen",
      CREATE_POST: "Neuen Beitrag erstellen",
      PAGES: "Seiten",
      FEATURES: "Funktionen",
      APPS: 'Apps',
      HOME: "Start"
    },
    AUTH: {
      GENERAL: {
        OR: "Oder",
        SUBMIT_BUTTON: "Absenden",
        NO_ACCOUNT: "Sie haben kein Konto?",
        SIGNUP_BUTTON: "Registrieren",
        FORGOT_BUTTON: "Passwort vergessen",
        BACK_BUTTON: "Zurück",
        PRIVACY: "Datenschutz",
        LEGAL: "Impressum",
        CONTACT: "Kontakt"
      },
      LOGIN: {
        TITLE: "Kontoanmeldung",
        BUTTON: "Anmelden"
      },
      FORGOT: {
        TITLE: "Passwort vergessen?",
        DESC: "Geben Sie Ihre E-Mail-Adresse ein, um Ihr Passwort zurückzusetzen",
        SUCCESS: "Ihr Konto wurde erfolgreich zurückgesetzt."
      },
      REGISTER: {
        TITLE: "Registrieren",
        DESC: "Geben Sie Ihre Daten ein, um Ihr Konto zu erstellen",
        SUCCESS: "Ihr Konto wurde erfolgreich erstellt."
      },
      INPUT: {
        EMAIL: "E-Mail-Adresse",
        FULLNAME: 'Name',
        PASSWORD: "Passwort",
        CONFIRM_PASSWORD: "Passwort bestätigen",
        USERNAME: "Nutzername"
      },
      VALIDATION: {
        INVALID: "{{name}} ist ungültig",
        REQUIRED: "{{name}} ist erforderlich",
        MIN_LENGTH: "Mindestlänge für {{name}} ist {{min}}",
        AGREEMENT_REQUIRED: "Sie müssen die allgemeinen Geschäftsbedingungen akzeptieren",
        NOT_FOUND: "{{name}} wurde nicht gefunden",
        INVALID_LOGIN: "Die Anmeldedaten sind falsch",
        REQUIRED_FIELD: "Erforderliches Feld",
        MIN_LENGTH_FIELD: "Minimale Feldlänge:",
        MAX_LENGTH_FIELD: "Maximale Feldlänge:",
        INVALID_FIELD: "Feld ist ungültig"
      }
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: "Anzahl ausgewählter Einträge: ",
        ALL: "Alle",
        SUSPENDED: "Ausgesetzt",
        ACTIVE: "Aktiv",
        FILTER: 'Filter',
        BY_STATUS: "nach Status",
        BY_TYPE: "nach Typ",
        BUSINESS: "Unternehmen",
        INDIVIDUAL: "Einzelperson",
        SEARCH: "Suchen",
        IN_ALL_FIELDS: "in allen Feldern"
      },
      ECOMMERCE: 'eCommerce',
      CUSTOMERS: {
        CUSTOMERS: "Kunden",
        CUSTOMERS_LIST: "Kundenliste",
        NEW_CUSTOMER: "Neuer Kunde",
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: "Kunde löschen",
          DESCRIPTION: "Sind Sie sicher, dass Sie diesen Kunden dauerhaft löschen möchten?",
          WAIT_DESCRIPTION: "Kunde wird gelöscht …",
          MESSAGE: "Kunde wurde gelöscht"
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: "Kunden löschen",
          DESCRIPTION: "Sind Sie sicher, dass Sie diese Kunden dauerhaft löschen möchten?",
          WAIT_DESCRIPTION: "Kunden werden gelöscht …",
          MESSAGE: "Ausgewählte Kunden wurden gelöscht"
        },
        UPDATE_STATUS: {
          TITLE: "Status ausgewählter Kunden erfolgreich aktualisiert",
          MESSAGE: "Der Status der ausgewählten Kunden wurde erfolgreich aktualisiert"
        },
        EDIT: {
          UPDATE_MESSAGE: "Kunde wurde aktualisiert",
          ADD_MESSAGE: "Kunden wurden erstellt"
        }
      }
    },
    Jan: 'Jan',
    Feb: 'Feb',
    Mar: "Mär",
    Apr: 'Apr',
    May: "Mai",
    Jun: 'Jun',
    Jul: 'Jul',
    Aug: 'Aug',
    Sep: "Sep",
    Oct: "Okt",
    Nov: 'Nov',
    Dec: "Dez",
    Clicks: "Geklickt",
    Opens: "Geöffnet",
    '< 7 days': "< 7 Tage",
    '7-14 Days': "7–14 Tage",
    '15-30 Days': "15–30 Tage",
    '31-60 Days': "31–60 Tage",
    '61-90 Days': "61–90 Tage",
    '> 90 Days': "> 90 Tage",
    '< 1 month': "< 1 Monat",
    '1-2 Months': "1–2 Monate",
    '2-6 Months': "2–6 Monate",
    '6-12 Months': "6–12 Monate",
    '> 1 Year': "> 1 Jahr",
    Sent: "Gesendet",
    Delivered: "Zugestellt",
    Open: "Öffnen",
    Spam: 'Spam',
    Click: "Klick",
    HardBounce: 'Hard Bounce',
    SoftBounce: 'Soft Bounce',
    Unsubscribe: "Abmelden",
    InvalidEmail: "Ungültige E-Mail-Adresse",
    Blocked: "Geblockt",
    Complaint: "Beschwerde",
    Deferred: "Aufgeschoben",
    ListAddition: "Listeneintrag",
    Overview: "Übersicht",
    "Guest List": "Gästeliste",
    "Guest Lists": "Gästelisten",
    "Guest Segments": "Gastsegmente",
    Campaigns: "Kampagnen",
    Templates: "Vorlagen"
  }
};
