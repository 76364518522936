import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FilterType } from '../_models/crm/filters/filter-type.enum';
import { FilterTypeComparers } from '../_models/crm/filters/filter-type-comparers.model';
import { SegmentFilter } from '../_models/crm/segment-filter.model';
import * as moment from 'moment';
import { CrmService } from './crm.service';
import { forkJoin, Observable } from 'rxjs';
import { SegmentFull } from '../_models/crm/segment-full.model';
import * as _ from 'lodash';
import { first, map, switchMap } from 'rxjs/operators';
import { TranslationService } from '../_base/layout';

@Injectable({
	providedIn: 'root'
})
export class CrmFilterService {
	public filterTypeComparers: FilterTypeComparers[] = [
		{
			filterType: FilterType.AnniversaryDate,
			comparers: [
				{
					comparerName: "anniversarybetweenfuturedayscompare",
					displayName: 'CONNECTS.SEGMENTS.BETWEEN_FUTURE',
					joinString: 'CONNECTS.SEGMENTS.AND',
					suffix: 'CONNECTS.SEGMENTS.DAYS'
				}
			]
		},
		{
			filterType: FilterType.Date,
			comparers: [
				{
					comparerName: "betweendatecompare",
					displayName: 'CONNECTS.SEGMENTS.BETWEEN_DATES',
					joinString: 'CONNECTS.SEGMENTS.AND'
				},
				{
					comparerName: "betweenfuturedayscompare",
					displayName: 'CONNECTS.SEGMENTS.BETWEEN_FUTURE',
					joinString: 'CONNECTS.SEGMENTS.AND',
					suffix: 'CONNECTS.SEGMENTS.DAYS'
				},
				{
					comparerName: "betweenpastdayscompare",
					displayName: 'CONNECTS.SEGMENTS.BETWEEN_PAST',
					joinString: 'CONNECTS.SEGMENTS.AND',
					suffix: 'CONNECTS.SEGMENTS.DAYS'
				},
				{
					comparerName: "betweenlastdayscompare",
					displayName: 'CONNECTS.SEGMENTS.LAST',
					suffix: 'CONNECTS.SEGMENTS.DAYS'
				}
			]
		},
		{
			filterType: FilterType.Number,
			comparers: [
				{
					comparerName: "equaltonumbercompare",
					displayName: 'CONNECTS.SEGMENTS.EQUAL'
				},
				{
					comparerName: "notequaltonumbercompare",
					displayName: 'CONNECTS.SEGMENTS.NOT_EQUAL'
				},
				{
					comparerName: "greaterthannumbercompare",
					displayName: 'CONNECTS.SEGMENTS.GREATER'
				},
				{
					comparerName: "lessthannumbercompare",
					displayName: 'CONNECTS.SEGMENTS.LESS'
				}
			]
		},
		{
			filterType: FilterType.Location,
			comparers: [
				{
					comparerName: "greaterthanlocationcompare",
					displayName: 'CONNECTS.SEGMENTS.GREATER',
					suffix: 'CONNECTS.SEGMENTS.KM'
				},
				{
					comparerName: "lessthanlocationcompare",
					displayName: 'CONNECTS.SEGMENTS.LESS',
					suffix: 'CONNECTS.SEGMENTS.KM'
				}
			]
		},
		{
			filterType: FilterType.Option,
			comparers: [
				{
					comparerName: "containsoptioncompare",
					displayName: 'CONNECTS.SEGMENTS.CONTAINS'
				},
				{
					comparerName: "notcontainsoptioncompare",
					displayName: 'CONNECTS.SEGMENTS.NOT_CONTAINS'
				}
			]
		},
		{
			filterType: FilterType.String,
			comparers: [
				{
					comparerName: "containsstringcompare",
					displayName: 'CONNECTS.SEGMENTS.CONTAINS'
				},
				{
					comparerName: "notcontainsstringcompare",
					displayName: 'CONNECTS.SEGMENTS.NOT_CONTAINS'
				},
				{
					comparerName: "equalstringcompare",
					displayName: 'CONNECTS.SEGMENTS.IS_EQUAL'
				},
				{
					comparerName: "notequalstringcompare",
					displayName: 'CONNECTS.SEGMENTS.IS_NOT_EQUAL'
				}
			]
		},
		{
			filterType: FilterType.Subscription,
			comparers: [
				{
					comparerName: "subscriptioncompare",
					displayName: 'CONNECTS.SEGMENTS.LEAST'
				}
			]
		}
	];

	public filterOptions = {
		"CONNECTS.SEGMENTS.DEMOGRAPHICS": [
			{ displayName: 'CONNECTS.SEGMENTS.ANNIVERSARY', fieldName:"anniversary_date", filterType: FilterType.AnniversaryDate },
			{ displayName: 'CONNECTS.SEGMENTS.BADGE', fieldName: "badge_name",filterType: FilterType.Option },
			{ displayName: 'CONNECTS.SEGMENTS.BIRTHDAY', fieldName: "date_of_birth", filterType: FilterType.AnniversaryDate },
			{ displayName: 'CONNECTS.SEGMENTS.COMPANY', fieldName: "company_name",filterType: FilterType.String },
			{ displayName: 'CONNECTS.SEGMENTS.EMAIL_ADDRESS', fieldName: "email",filterType: FilterType.String },
			{ displayName: 'CONNECTS.SEGMENTS.GENDER', fieldName: "gender",filterType: FilterType.Option },
			{ displayName: 'CONNECTS.SEGMENTS.LANGUAGE', fieldName: "language",filterType: FilterType.Option },
			{ displayName: 'CONNECTS.SEGMENTS.LIFETIME_ROOM', fieldName: "lifetime_spend", filterType: FilterType.Number },
			{ displayName: 'CONNECTS.SEGMENTS.LOCATION', fieldName: "location", filterType: FilterType.Location },
			{ displayName: 'CONNECTS.SEGMENTS.TOTAL_NUMBER_NIGHTS', fieldName: "lifetime_nights",filterType: FilterType.Number },
			{ displayName: 'CONNECTS.SEGMENTS.TOTAL_NUMBER_STAYS', fieldName: "lifetime_bookings",filterType: FilterType.Number }
		],
		"CONNECTS.SEGMENTS.MARKETING": [
			{ displayName: 'CONNECTS.SEGMENTS.SUBSCRIPTION', fieldName: "subscription_status", filterType: FilterType.Subscription }
		],
		"CONNECTS.SEGMENTS.STAY_DATA": [
			{ displayName: 'CONNECTS.SEGMENTS.BOOKING', fieldName: "booking_channel_name", filterType: FilterType.String },
			{ displayName: 'CONNECTS.SEGMENTS.BOOKING_DATE', fieldName: "created_date", filterType: FilterType.Date },
			{ displayName: 'CONNECTS.SEGMENTS.BOOKING_LEAD', fieldName: "lead_time", filterType: FilterType.Number },
			{ displayName: 'CONNECTS.SEGMENTS.CHECK_IN_DATE', fieldName: "check_in", filterType: FilterType.Date },
			{ displayName: 'CONNECTS.SEGMENTS.CHECK_OUT_DATE', fieldName: "check_out", filterType: FilterType.Date },
			{ displayName: 'CONNECTS.SEGMENTS.LENGTH', fieldName: "nights", filterType: FilterType.Number },
			{ displayName: 'CONNECTS.SEGMENTS.MARKET', fieldName: "market_code", filterType: FilterType.String },
			{ displayName: 'CONNECTS.SEGMENTS.RATE', fieldName: "rate_plan_code", filterType: FilterType.String },
			{ displayName: 'CONNECTS.SEGMENTS.ROOM', fieldName: "room_code", filterType: FilterType.String },
			{ displayName: 'CONNECTS.SEGMENTS.RATE_AVARAGE', fieldName: "adr_total_after_tax", filterType: FilterType.Number },
			{ displayName: 'CONNECTS.SEGMENTS.SOURCE', fieldName: "source_of_business", filterType: FilterType.String },
			{ displayName: 'CONNECTS.SEGMENTS.STAY', fieldName: "stay_date", filterType: FilterType.Date },
		]
	};

	constructor(private crmService: CrmService, private readonly translationService: TranslationService) { }

	public getSegments(update = false, hotelIds: number[] = null, pmsOnly = false): Observable<SegmentFull[]> {
		return this.translationService.currentLanguage$.pipe(switchMap(() =>
		  forkJoin([this.crmService.getLists(), this.crmService.getSegments(update)]).pipe(map((data) => {
			const crmLists = data[0];
			let allSegments = data[1];
			if (hotelIds && Array.isArray(hotelIds) && (hotelIds.length>0) && !!hotelIds[0] && (<any>hotelIds[0] !="null")) {
				let filteredLists = crmLists.filter(l => hotelIds.find(id => id == l.hotelId) && (!pmsOnly || (pmsOnly && l.isPms)));
				allSegments = allSegments.filter(s => s.crmListIds.find(id => filteredLists.find(l => l.crmListId === id)) && (!pmsOnly || (pmsOnly && !s.crmListIds.find(id => !filteredLists.find(f => f.crmListId == id)))));
				allSegments = allSegments.filter(s => !s.exclusiveHotelId || hotelIds.find(id => id==s.exclusiveHotelId));
			}
			allSegments.forEach(s => {
				if (s.crmListIds.length === 1) {
					let list = crmLists.find(c => c.crmListId == s.crmListIds[0]);
					if (list)
						s.listString = list.name;
					else
						s.listString = "1 " + this.translationService.translatePhrase('CONNECTS.CAMPAIGNS.LIST_SELECTED');
				} else if (_.isEqual(_.sortBy(s.crmListIds), _.sortBy(crmLists.map(l => l.crmListId)))) {
					s.listString = this.translationService.translatePhrase('CONNECTS.CAMPAIGNS.ALL_LISTS_SELECTED');
				} else {
					s.listString = s.crmListIds.length + this.translationService.translatePhrase('CONNECTS.CAMPAIGNS.LISTS_SELECTED');
				}

				s.filterStrings = this.mapFilterToText(s.filters);
			});

			return allSegments;
		}))));
	}

	public mapFilterToText(filters: SegmentFilter[][]): string[][] {
		let filterList = [].concat(...Object.values(this.filterOptions)).map((item) => ({ ...item, displayName: this.translationService.translatePhrase(item.displayName)}));
		let comparerList = [].concat(...this.filterTypeComparers.map(c => c.comparers)).map((item) => ({...item, displayName: this.translationService.translatePhrase(item.displayName), suffix: this.translationService.translatePhrase(item.suffix), joinString: this.translationService.translatePhrase(item.joinString) }));

		let orGroup = [];
		for (let grp of filters) {
			let andGroup = []
			for (let filter of grp) {
				let f = filterList.find(l => l.fieldName.toLowerCase() == filter.fieldName.toLowerCase());
				let c = comparerList.find(l => l.comparerName.toLowerCase() == filter.filterCompare.toLowerCase());
				if (f && c) {
					andGroup.push(`${f.displayName} ${c.displayName} ${filter.values.map(v => this.convertString(v, (c.suffix === "days") ? FilterType.Number : f.filterType)).join(c.joinString ? ' ' + c.joinString + ' ' : ', ')} ${c.suffix || ''}`);
				}
			}
			orGroup.push(andGroup);
		}
		return orGroup;
	}

	private convertString(val: string, filterType: FilterType) {
		if (filterType == FilterType.Date) {
			return moment(val).format('D MMM YYYY');
		} else if (filterType == FilterType.AnniversaryDate) {
			return moment(val).format('D MMM');
		}

		return val;
	}
}
